(function() {
	'use strict';
	angular
	.module("app")
	.controller('AdminController', AdminController);

	AdminController.$inject = ['$rootScope', '$state', '$transitions', '$q', '$location', 'Paging', '$http', 'Auth', 'currentAuth', '$firebaseArray', '$firebaseObject'];

	function AdminController($rootScope, $state, $transitions, $q, $location, Paging, $http, Auth, currentAuth, $firebaseArray, $firebaseObject) {
		$rootScope.jQueryReady();
		var vm = this;
		this.searchInput = "";
		this.search = function(){
			var result = "admin.404";
			for (var x in vm.menuItems){
				if(vm.menuItems.hasOwnProperty(x)){
					if(x.indexOf(vm.searchInput) !== -1){
						if(vm.menuItems[x].module){
							result = vm.menuItems[x].module;
							break;
						}
						result = x;
						break;
					}
				}
			}
			$state.go(result);
			$('.search-box').fadeOut();
			vm.searchInput = "";
		};
		this.auth = Auth;
		this.firebaseUser = currentAuth;
		this.displayName = angular.copy(this.firebaseUser.displayName || "Demo Account");
		var d = new Date();
		this.currentYear = d.getFullYear();
		this.menuItems = {
			"admin.storage" : {"icon" : "fa-barcode", "href": '/', "label": { "en": 'Storage', "el": 'Αποθήκη'}, "active": true },
			"admin.inout" : {"icon" : "fa-exchange", "href": 'inout', "label": { "en": 'Losses - Purchases', "el": 'Αγορές - Απώλειες'}, "active": true },
			"admin.products" : {"icon" : "fa-cube", "href": 'products', "label": { "en": 'Products', "el": 'Είδη'}, "active": true, "module": "admin.products.list"},
			"admin.404" : {"icon" : "",  "href": '404', "label": { "en": '404 - Not Found', "el": '404 - Η σελίδα δεν βρέθηκε'}, "active": false }
		};
		this.init = function(){
			var module = $state.current.module || $rootScope.mainState;
			this.currentPage = this.getCurrentPage(module);
		};
		this.getCurrentPage = function(module){
			return this.menuItems[module] ? this.menuItems[module] : this.menuItems[$rootScope.mainState];
		};
		this.logout = $rootScope.logout;
		function htmlDecode(value) {
			return $("<textarea/>").html(value).text();
		}
		function htmlEncode(value) {
			return $('<textarea/>').text(value).html();
		}
		this._ = function(translation, decode){
			if(!translation)
				return "";
			var txt = translation[$rootScope.lang] ? translation[$rootScope.lang] : (angular.isDefined(translation['el']) ? translation['el'] : translation);
			if(angular.isDefined(decode)){
				txt = htmlDecode(txt);
			}
			return txt;
		};

		var translations = {
			"Find" : "Εύρεση",
			"New Product" : "Νέο Είδος",
			"Product" : "Προϊόν",
			"Category" : "Κατηγορία",
			"Total Quantity" : "Συνολική Ποσότητα",
			"Update Date" : "Ημ/νία Ενημέρωσης",
		};
		this.__ = function(text){
			if($rootScope.lang !== "en" && translations[text]){
				return translations[text];
			}
			return text;
		};

		this.actions = {
			delete : function(collection, id){
				if(!confirm("Είσαι σίγουρος/η ότι θές να διαγράψεις αυτήν την καταχώρηση? Η ενέργεια είναι μη αναστρέψιμη!")){
					return;
				}
				delete collection[id];
			},
			arrayDown : function (arr, index) {
				arr.splice(index + 1, 0, arr.splice(index, 1)[0]);
			},
			arrayUp : function (arr, index) {
				arr.splice(index - 1, 0, arr.splice(index, 1)[0]);
			}
		};

		this.helpers = {
			getDate: function(str){
				var d = new Date(str);
				return d.present();
			}
		};
	}

})();
/**
 * Created by Ignatis on 06/05/2018.
 */
(function(){
    'use strict';

    angular
        .module("app", ["ngSanitize", 'ngMessages', 'ui.router', 'firebase', 'oc.lazyLoad'])
        .constant('paramEncode', paramEncode)
        .run(run)
        .config(config)
        .directive('html', html)
        .directive('errSrc', errSrc)
        .directive('ckEditor', ckEditor)
        .filter('orderObjectBy', orderObjectBy)
        .filter('toArray', toArray)
        .filter('offset', offset);


    function errSrc() {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                    if (attrs.src !== attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });

                attrs.$observe('ngSrc', function(value) {
                    if (!value && attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });
            }
        }
    }

    function paramEncode(obj) {
        var query = '', name, value, fullSubName, subName, subValue, innerObj, i;

        for(name in obj) {
            value = obj[name];

            if(value instanceof Array) {
                for(i=0; i<value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + '[' + i + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += paramEncode(innerObj) + '&';
                }
            }
            else if(value instanceof Object) {
                for(subName in value) {
                    subValue = value[subName];
                    fullSubName = name + '[' + subName + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += paramEncode(innerObj) + '&';
                }
            }
            else if(value !== undefined && value !== null)
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
        }

        return query.length ? query.substr(0, query.length - 1) : query;
    }

    function orderObjectBy(){
        return function (obj, field, reverse) {
            if (!(obj instanceof Object)) {
                return obj;
            }
            var newArray = Object.keys(obj).map(function (key) {
                return Object.defineProperty(obj[key], '$key', {__proto__: null, value: key});
            });

            newArray.sort(function (a, b) {
                return (a[field] > b[field] ? 1 : -1);
            });
            if(reverse) newArray.reverse();
            return newArray;
        }

    }

    function toArray() {
        return function (obj) {
            if (!(obj instanceof Object)) {
                return obj;
            }

            return Object.keys(obj).map(function (key) {
                return Object.defineProperty(obj[key], '$key', {__proto__: null, value: key});
            });
        }
    }

	function offset(){
		return function(input, start){
			start = parseInt(start, 10);
			return input.slice(start);
		};
	}

	html.$inject = ['$rootScope'];
    function html($rootScope) {
        return {
            restrict: 'E',
            link : function(scope, element, attrs) {
	            $rootScope.lang = location.hostname.indexOf("en") >= 0 ? "en" : "el";
                attrs.$set("lang", $rootScope.lang);    // Set the "lang" value dynamically here
                if($rootScope.lang === 'el'){
	                document.title = 'Storage | Διαχείριση';
                }else{
	                document.title = 'Storage | Admin';
                }
            }
        };
    }

	function ckEditor() {
		return {
			require: '?ngModel',
			link: function (scope, elm, attr, ngModel) {
				var editorOptions;
				if (attr.ckEditor === 'minimal') {
					// minimal editor
					editorOptions = {
						toolbar: [
							{ name: 'document', items: ['Source', 'Save', 'EditMode'] },
							{ name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteFromWord', '-', 'Undo', 'Redo'] },
							{ name: 'basic', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat'] },
							{ name: 'links', items: ['Link', 'Unlink'] },
							{ name: 'style', items: ['Font']},
							{ name: 'tools', items: [] },
							{ name: 'insert', items: ['Image', 'SpecialChar'] }
						],
						basicEntities : true,
						allowedContent: true,
						resize_enabled : false,
						defaultLanguage : 'el',
						language : 'el',
						enterMode : CKEDITOR.ENTER_BR
					};
				}else if(attr.ckEditor === 'full'){
					editorOptions = {
						basicEntities : true,
						allowedContent: true,
						resize_enabled : true,
						defaultLanguage : 'el',
						language : 'el',
						enterMode : CKEDITOR.ENTER_BR
					};

				}else{
					editorOptions = {
						toolbar: [
							{ name: 'document', items: ['Source'] },
							{ name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteFromWord', '-', 'Undo', 'Redo'] },
							{ name: 'basic', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat'] },
							{ name: 'links', items: ['Link', 'Unlink'] },
							{ name: 'style', items: ['Font']},
							{ name: 'tools', items: [] },
							{ name: 'insert', items: ['Image', 'SpecialChar'] }
						],
						basicEntities : true,
						allowedContent: true,
						resize_enabled : false,
						defaultLanguage : 'el',
						language : 'el',
						enterMode : CKEDITOR.ENTER_BR
					};
				}
				var ck = CKEDITOR.replace(elm[0], editorOptions);
				if(attr.ckEditor === ''){
					setupExtraEvents();
				}
				ck.addCommand("EnableEditModeCommand", { // create named command
					exec: function(edt) {
						edt.setReadOnly(false);
						ck.commands.EnableEditModeCommand.setState(CKEDITOR.TRISTATE_DISABLED);
					},
					readOnly: true
				});

				ck.ui.addButton('EditMode', { // add new button and bind our command
					label: "Επεξεργασία",
					command: 'EnableEditModeCommand',
					toolbar: 'document',
					icon : ''
				});
				if (!ngModel) return;
				ck.on('instanceReady', function () {
					ck.setData(ngModel.$viewValue);
				});
				function updateModel() {
					ngModel.$setViewValue(ck.getData());
				}
				ck.on('save', function(evt){
					evt.cancel();
					updateModel();
					ck.setReadOnly(true);
					ck.commands.EnableEditModeCommand.setState(CKEDITOR.TRISTATE_ON);
				});
				function setupExtraEvents(){
					ck.on('change', updateModel);
					ck.on('key', updateModel);
					ck.on('dataReady', updateModel);
					ck.on('afterPaste', updateModel);
					ck.on('selectionChange', updateModel);
				}
				ngModel.$render = function (value) {
					ck.setData(ngModel.$viewValue);
				};
			}
		};
	}



    run.$inject = ['firebase', '$rootScope', '$location', '$state', '$firebaseAuth'];
    function run(firebase, $rootScope, $location, $state, $firebaseAuth) {
	    Date.prototype.addDays = function(days) {
		    var dat = new Date(this.valueOf());
		    dat.setDate(dat.getDate() + days);
		    return dat;
	    };
	    Date.prototype.present = function(forPhp) {
		    var month = this.getMonth() + 1;
		    var day = this.getDate();
		    if(forPhp){
			    return this.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
		    }
		    return  (day < 10 ? "0" + day : day) + "/" + (month < 10 ? "0" + month : month) + "/" + this.getFullYear();
	    };
	    Date.prototype.checkDate = function(date) {
		    return this.forPhp() === date;
	    };
        firebase.initializeApp({
            apiKey: "AIzaSyAXJeog9Zav2zg6VIVazIazMMbX3XFmlPk",
            authDomain: "ignatisd-1e430.firebaseapp.com",
            databaseURL: "https://ignatisd-1e430.firebaseio.com",
            storageBucket: "ignatisd-1e430.appspot.com",
            messagingSenderId: "291306326277"
        });
	    $rootScope.mainState = "admin.storage";
	    $state.defaultErrorHandler(function(error) {
		    if (error.detail === "AUTH_REQUIRED") {
			    return $state.go('login');
		    }
	    });

	    $rootScope.logout = function(){
		    $firebaseAuth().$signOut();
		    $state.go("login");
	    };

	    $rootScope.jQueryReady = jQueryReady;
    }


    config.$inject = ['$httpProvider','$stateProvider', '$urlRouterProvider', '$ocLazyLoadProvider', '$locationProvider', 'paramEncode'];
    function config($httpProvider, $stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $locationProvider, paramEncode){
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        $httpProvider.defaults.transformRequest = [function(data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? paramEncode(data) : data;
        }];
        $locationProvider.html5Mode(true);
	    $ocLazyLoadProvider.config({
		    debug: true,
		    modules: [
		    	{
				    name: 'loginModule',
				    files: [
				    	'assets/js/LoginController.min.js'
				    ]
			    }
		    ]
	    });
	    $urlRouterProvider.otherwise("/404");

	    $stateProvider
	    .state('login', {
		    url: '/login',
		    controllerAs: 'Login',
		    controller: 'LoginController',
		    templateUrl: 'partials/login.html',
		    resolve: {
		    	// Lazy Load login
			    loadMyModule: ['$ocLazyLoad', function($ocLazyLoad) {
				    // you can lazy load files for an existing module
				    return $ocLazyLoad.load('loginModule');
			    }],
			    // controller will not be loaded until $requireSignIn resolves
			    // Auth refers to our $firebaseAuth wrapper in the factory below
			    "currentAuth": ["Auth", function(Auth) {
				    // $requireSignIn returns a promise so the resolve waits for it to complete
				    // If the promise is rejected, it will throw a $routeChangeError (see above)
				    return Auth.$waitForSignIn();
			    }]
		    }
	    });

	    $stateProvider
	    .state('admin', {
		    abstract: true,
		    controllerAs: 'Admin',
		    controller: 'AdminController',
		    templateUrl: 'partials/admin.html',
		    resolve: {
			    "currentAuth": ["Auth", function(Auth) {
				    return Auth.$requireSignIn();
			    }]
		    }
	    })
	    .state('admin.storage', {
		    url: '/',
		    templateUrl: 'partials/storage/storage.html',
		    module: 'admin.storage'
	    })
	    .state('admin.inout', {
		    url: '/inout',
		    templateUrl: 'partials/inout/inout.html',
		    module: 'admin.inout'
	    })
	    .state('admin.products', {
		    abstract: true,
		    controllerAs: 'Products',
		    controller: 'ProductsController',
		    template: '<ui-view/>',
	    })
	    .state('admin.products.list', {
		    url: '/products',
		    templateUrl: 'partials/products/list.html',
		    module: 'admin.products'
	    })
	    .state('admin.products.edit', {
		    url: '/products/edit/:product_id',
		    templateUrl: 'partials/products/edit.html',
		    module: 'admin.products'
	    })
	    .state('admin.products.add', {
		    url: '/products/add',
		    templateUrl: 'partials/products/add.html',
		    module: 'admin.products'
	    })
	    .state('admin.404', {
		    url: '/404',
		    templateUrl: 'partials/404.html',
		    module: 'admin.404'
	    });
    }

    function jQueryReady(){
	    $(document).ready(function () {
		    $("#mask").fadeOut("slow");
		    var body = $('body');
		    body.removeClass("login-page").addClass("home-page");

			// ------------------------------------------------------- //
		    // Search Box
		    // ------------------------------------------------------ //
		    body.on('click', '#search', function (e) {
			    e.preventDefault();
			    $('.search-box').fadeIn();
		    });
		    body.on('click', '.dismiss', function (e) {
			    $('.search-box').fadeOut();
		    });

		    // ------------------------------------------------------- //
		    // Card Close
		    // ------------------------------------------------------ //
		    body.on('click', '.card-close a.remove', function (e) {
			    e.preventDefault();
			    $(this).parents('.card').fadeOut();
		    });


		    // ------------------------------------------------------- //
		    // Adding fade effect to dropdowns
		    // ------------------------------------------------------ //
		    body.on('show.bs.dropdown', '.dropdown', function () {
			    $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
		    });
		    body.on('hide.bs.dropdown', '.dropdown', function () {
			    $(this).find('.dropdown-menu').first().stop(true, true).fadeOut();
		    });

		    // ------------------------------------------------------- //
		    // Sidebar Functionality
		    // ------------------------------------------------------ //
		    body.on('click', '#toggle-btn', function (e) {
			    e.preventDefault();
			    $(this).toggleClass('active');

			    $('.side-navbar').toggleClass('shrinked');
			    $('.content-inner').toggleClass('active');

			    if ($(window).outerWidth() > 1183) {
				    if ($(this).hasClass('active')) {
					    $('.navbar-header .brand-small').hide();
					    $('.navbar-header .brand-big').show();
				    } else {
					    $('.navbar-header .brand-small').show();
					    $('.navbar-header .brand-big').hide();
				    }
			    }

			    if ($(window).outerWidth() < 1183) {
				    $('.navbar-header .brand-small').show();
			    }
		    });

		    // ------------------------------------------------------- //
		    // Transition Placeholders
		    // ------------------------------------------------------ //
		    $('input.input-material').on('focus', function () {
			    $(this).siblings('.label-material').addClass('active');
		    });

		    $('input.input-material').on('blur', function () {
			    $(this).siblings('.label-material').removeClass('active');

			    if ($(this).val() !== '') {
				    $(this).siblings('.label-material').addClass('active');
			    } else {
				    $(this).siblings('.label-material').removeClass('active');
			    }
		    });

	    });
    }

    angular
        .module("app")
        .factory("eventBase", eventBase)
        .factory("authUsers", authUsers)
        .factory("Auth", Auth)
        .directive("pagination", pagination);

	eventBase.$inject = ["$firebaseObject"];

    function eventBase($firebaseObject) {
        var ref = firebase.database().ref().child("storageBase");
        return $firebaseObject(ref);
    }

    authUsers.$inject = ["$firebaseArray"];

    function authUsers($firebaseArray) {
        var ref = firebase.database().ref().child("storageUsers");
        return $firebaseArray(ref);
    }

    Auth.$inject = ["$firebaseAuth"];

    function Auth($firebaseAuth) {
        return $firebaseAuth();
    }

	function pagination(){
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				items: '=ngModel'
			},
			replace: true,
			template: pagingTemplate
		}
	}
//noinspection JSAnnotator
	const pagingTemplate =
    `<nav class="p-3 d-flex justify-content-between">
		<div class="d-flex align-items-center">
			<span ng-bind-html="items.getText()"></span>
		</div>
		<ul class="pagination">
			<li class="page-item" ng-class="items.prevPageDisabled()">
				<a class="page-link" href="" ng-click="items.prevPage()" aria-label="Previous">
					<span aria-hidden="true">&laquo;</span>
        			<span class="sr-only">Previous</span>
				</a>
			</li>
			<li class="page-item" ng-if="items.pageCount() > 1 && items.controls.currentPage > 0">
				<a class="page-link" href="" ng-click="items.setPage(0)">1</a>
			</li>

			<li class="page-item disabled" ng-show="items.pageCount() >= 4 && items.controls.currentPage >= 3">
				<a class="page-link" href="">...</a>
			</li>
			<li class="page-item" ng-if="items.pageCount() >= 3 && items.controls.currentPage >= 2">
				<a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage-1)">{{items.controls.currentPage}}</a>
			</li>

			<li class="page-item active">
				<a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage)">{{items.controls.currentPage + 1}}</a>
			</li>

			<li class="page-item" ng-if="items.pageCount() >= 3 && items.controls.currentPage < items.pageCount() - 2">
				<a class="page-link" href="" ng-click="items.setPage(items.controls.currentPage+1)">{{items.controls.currentPage + 2}}</a>
			</li>
			<li class="page-item disabled" ng-show="items.pageCount() >= 4 && items.controls.currentPage < items.pageCount() - 3">
				<a class="page-link" href="">...</a>
			</li>

			<li class="page-item" ng-if="items.pageCount() > 1 && items.controls.currentPage < items.pageCount() - 1">
				<a class="page-link" href="" ng-click="items.setPage(items.pageCount() - 1)">{{items.pageCount()}}</a>
			</li>
			<li class="page-item" ng-class="items.nextPageDisabled()">
				<a class="page-link" href="" aria-label="Next" ng-click="items.nextPage()">
					<span aria-hidden="true">&raquo;</span>
        			<span class="sr-only">Next</span>
				</a>
			</li>
		</ul>
	</nav>
`;
	angular
		.module("app")
		.service("Paging", Paging);
	/**
	 * These variables and functions are used to provide a basic paging and sorting system.
	 * @param {Object} controls - The object containing the pagination controls
	 * @param {Array} collection - The array of objects to paginate
	 */
	function Paging(){
		return function(collection, controls){
			this.fn = null;
			this.controls = {
				quantity    : (controls && "quantity" in controls ? controls.quantity : 50),
				propertyName: (controls && "propertyName" in controls ? controls.propertyName : "id"),
				reverse     : (controls && "reverse" in controls ? controls.reverse : false),
				currentPage : (controls && "currentPage" in controls ? controls.currentPage : 0),
				search      : (controls && "search" in controls ? controls.search : {})
			};
			this.defaults = JSON.stringify(this.controls);
			this.collection = collection;
			this.filtered   = [];

			this.setControl = function(control, value){
				this.controls[control] = value;
			};
			this.resetControls = function(){
				this.controls = JSON.parse(this.defaults);
			};
			this.clearSearch = function(){
				this.controls.search = JSON.parse(this.defaults).search;
			};
			this.clearCollection = function(){
				this.collection = [];
				this.setPage(0);
			};

			this.setCollection = function(collection){
				this.collection = collection;
			};
			this.setFakeCollection = function(num){
				if(!num){
					return;
				}
				this.collection = new Array(num);
			};

			this.setFiltered = function(items){
				this.filtered = items;
			};

			this.setPageChangeFn = function(fn){
				this.fn = fn;
			};

			this.remove = function(item, property){
				if(!property || !item || !(property in item))
					return;
				var index = this.collection.map(function(e){
					return e[property];
				}).indexOf(item[property]);

				if(index >= 0)
					this.collection.splice(index, 1);
			};
			this.getText = function(){
				var text  = 'Αποτελέσματα <b>'+(this.filtered.length > 0 ? this.controls.currentPage*this.controls.quantity + 1 : 0)+'</b>';
				text += ' εώς <b>'+(this.filtered.length > (this.controls.currentPage+1)*this.controls.quantity ? (this.controls.currentPage+1)*this.controls.quantity : this.filtered.length)+'</b>';
				text += ' από <b>'+(this.filtered.length)+'</b> (<b>'+(this.collection.length - this.filtered.length)+'</b> filtered)';
				return text;
			};

			this.sortBy = function(propertyName){
				this.controls.reverse = (this.controls.propertyName === propertyName) ? !this.controls.reverse : false;
				this.controls.propertyName = propertyName;
			};

			this.range = function() {
				var rsiz = Math.ceil(this.filtered.length / this.controls.quantity);
				var rangeSize = (rsiz > 5) ? 5 : rsiz;
				var ret = [];
				var start;

				start = this.controls.currentPage;
				if (start > this.pageCount() - rangeSize - 1) {
					start = this.pageCount() - rangeSize;
				}

				for (var i = start; i < start + rangeSize; i++) {
					ret.push(i);
				}
				return ret;
			};

			this.pageCount = function() {
				return Math.ceil(this.filtered.length/this.controls.quantity);
			};

			this.prevPage = function() {
				if(this.controls.currentPage > 0) {
					this.setPage(this.controls.currentPage - 1);
				}
			};

			this.nextPage = function() {
				if (this.controls.currentPage < this.pageCount() - 1) {
					this.setPage(this.controls.currentPage + 1);
				}
			};

			this.prevPageDisabled = function() {
				return this.controls.currentPage === 0 ? "disabled" : "";
			};

			this.nextPageDisabled = function() {
				return this.controls.currentPage === this.pageCount() - 1 ? "disabled" : "";
			};

			this.setPage = function(page) {
				if(!page){
					page = 0;
				}
				if(this.fn !== null && typeof this.fn === "function"){
					this.fn(page);
				}else{
					this.controls.currentPage = page;
				}
			};

		}
	}
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', function() {
			navigator.serviceWorker.register('serviceworker.js').then(function (reg) {
				reg.onupdatefound = function() {
					const installingWorker = this.installing;
					installingWorker.onstatechange = function () {
						switch (installingWorker.state) {
							case 'installed':
								if (navigator.serviceWorker.controller) {
									console.log('New or updated content is available.');
								} else {
									console.log('Content is now available offline!');
								}
								break;
							case 'redundant':
								console.warn('The installing service worker became redundant.');
								break;
						}
					};
				};
			}).catch(function (e) {
				console.error('Error during service worker registration:', e);
			});
		});
	}
})();


(function() {
	'use strict';
	angular
	.module("app")
	.controller('ProductsController', ProductsController);

	ProductsController.$inject = ['$rootScope', '$stateParams', '$q', '$location', 'Paging', '$http', 'Auth', 'currentAuth', '$firebaseArray', '$firebaseObject'];

	function ProductsController($rootScope, $stateParams, $q, $location, Paging, $http, Auth, currentAuth, $firebaseArray, $firebaseObject) {
		var vm = this;
		vm.list = new Paging([], {quantity: 100, propertyName: 'name'});
		var products = [
			{
				"image" : "table.png",
				"name" : "Τραπέζι ορθογώνιο ξύλινο",
				"category" : "Εξοπλισμός",
				"quantity" : 200,
				"updated_at" : "2017-08-20",
			}
		];
		vm.list.setCollection(products);
		// console.log($stateParams);
	}
})();